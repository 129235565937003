.landing-main {
	display: block;
	background: rgba(255, 255, 255, 0.38);
	background-image: url(../../assets/solar-hub-1.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	border-style: none;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0px;
	border-radius: 0px;
	width: 100%;
	/* height: 786px; */
	position: relative;
	padding-bottom: 20px;
	/* background-blend-mode: difference; */
}

.landing-title {
	line-height: 20px;
	font-weight: 500;
	font-size: 20px;
	font-style: normal;
}

.landing-text-color {
	color: black;
}

.landing-text-content {
	background-color: white;
	opacity: 0.7;
	border-radius: 5px;
	box-shadow: 0 14px 44px 0 #000000bf;
	line-height: 2.5rem;
}