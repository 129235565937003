.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: block;
  width: 100%;
}

.review-count {
  font-weight: 300;
  font-size: '16px';
  color: 'rgb(255, 255, 255)';
  font-style: 'normal';
}

.quote-button {
  padding: 20px 50px;
  border-radius: 50px;
  background: rgb(192, 255, 0) no-repeat scroll 0% 0% / auto padding-box border-box;
  color: rgb(0, 0, 0);
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  text-decoration: none solid rgb(0, 0, 0);
}

.quote-button:hover {
  background-color: rgb(24 61 36);
  color: white;
}

.contact-btn {
  padding: 10px;
  border-radius: 10px;
  background: rgb(192, 255, 0) no-repeat scroll 0% 0% / auto padding-box border-box;
  color: rgb(0, 0, 0);
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  text-decoration: none solid rgb(0, 0, 0);
  border: 1px solid rgb(192, 255, 0);
}

.contact-btn:hover {
  background-color: rgb(24, 61, 36);
  color: white;
}

.theme-text {
  color: rgb(89, 0, 255)
}

.scroll-margin-top-150 {
  scroll-margin-top: 150px;
}