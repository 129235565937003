.quota-box {
    margin: 0;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 14px 44px 0px;
    background: rgb(255, 255, 255);
    border-style: none;
    border-radius: 5px;
    display: block;
    z-index: 84;
    height: 550px;
    position: relative;
    font-size: 14px;
    /* font: 14px / 17px Arial, sans-serif; */
    line-height: 17px;
    scroll-margin-top: 150px;
}

.quota-heading {
    line-height: 1.2;
    font-size: 18px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    font-style: normal;
}

.form-check-input {
    border-color: green
}